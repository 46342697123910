<template>
  <div class='pb-8 pl-2 lg:pl-8'>
    <portal to='headerSpace'>
      <div class='flex flex-row'>
        <h1 class='text-lg lg:text-3xl font-semibold tracking-wide'>간접투자원장조회</h1>
      </div>
    </portal>
    <div class='flex flex-row py-2 items-stretch'>
      <el-date-picker
        v-model='createdOn'
        style='width: 9rem;'
        type='date'
        placeholder='기준일'
        value-format='yyyy-MM-dd'
        @change='selectLpPortfolios'>
      </el-date-picker>
      <input
        class='form-input mx-1'
        type='text'
        placeholder='투자펀드'
        v-model='investmentName'
        @keyup.enter='selectLpPortfolios' />
      <button
        class='hover:bg-gray-100 rounded-md p-2 border'
        @click='selectLpPortfolios'>
        <refresh-cw-icon class='h-4' />
      </button>
    </div>
    <el-radio-group v-for='fundFormOption in fundFormOptions'
      :key='fundFormOption'
      v-model='fundForm'
      size='large'
      class='py-2'
      @change='selectLpPortfolios'>
      <el-radio-button :label='fundFormOption'></el-radio-button>
    </el-radio-group>
    <div class='lp-portfolio-table-container overflow-auto py-2'>
      <table class='text-base table-fixed'>
        <thead>
          <tr class='bg-white border-t sticky top-0'>
            <td class='px-2 py-2 text-right border-r border-l'></td>
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.share_code'
              keyName='share_code'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='펀드코드'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <td class='px-2 py-2 text-left font-semibold border-r shadow-md'>펀드명</td>
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.base_asset_name'
              keyName='base_asset_name'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='기초자산'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.organization_value'
              keyName='organization_value'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='결성총액'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.contract_value'
              keyName='contract_value'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='당사약정총액'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.remain_investment_value'
              keyName='remain_investment_value'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='미출자잔액'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.start_date'
              keyName='start_date'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='설립일자'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <table-header-filter-select
              v-bind:filter-texts.sync='filterTexts.end_date'
              keyName='end_date'
              v-bind:sorting-dir.sync='sortDir'
              v-bind:sorting-key.sync='sortKey'
              defaultLabel='만기일자'
              :optionObject='portfolios'
              class='px-2 py-2 text-left border-r border-l' />
            <td class='px-2 py-2 text-left border-r shadow-md font-semibold'>GP</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for='(portfolio, index) in filteredData'
            :key='`portfolio-${index}`'
            class='bg-white hover:bg-blue-100'
            @click='openDetail(portfolio)' >
            <td class='px-2 py-2 text-left border-r'>{{ index+1 }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ portfolio.share_code }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ portfolio.name }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ portfolio.base_asset_name }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.organization_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.contract_value) }}</td>
            <td class='px-2 py-2 text-right border-r'>{{ toNumber(portfolio.remain_investment_value) }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ portfolio.start_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ portfolio.end_date }}</td>
            <td class='px-2 py-2 text-left border-r'>{{ portfolio.gp }}</td>
          </tr>
        </tbody>
      </table>
    <portal to='modals'>
      <lp-portfolio-details v-if='sideOpen'
        :display-in-modal='true'
        :fund-form='fundForm'
        class='px-16'
        @done-editing='doneEditing' />
    </portal>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import numbro                   from 'numbro'
import dayjs                    from 'dayjs'
import { RefreshCwIcon } from 'vue-feather-icons'
import LpPortfolioDetails       from '@/views/portfolio/LpPortfolioDetails.vue'
import TableHeaderFilterSelect  from '@/components/TableHeaderFilterSelect.vue'


export default {
  name: 'LpPortfolios',
  components: {
    RefreshCwIcon,
    LpPortfolioDetails,
    TableHeaderFilterSelect,
  },
  data () {
    return {
      createdOn: dayjs(new Date()).format('YYYY-MM-DD'),
      fundForm: '프로젝트',
      sideOpen: false,
      investmentName: '',
      sortDir: '',
      sortKey: '',
      filterTexts: {
        share_code: [],
        base_asset_name: [],
        organization_value: [],
        contract_value: [],
        remain_investment_value: [],
        start_date: [],
        end_date: [],
      },
      filterKeys: [
        'share_code',
        'base_asset_name',
        'organization_value',
        'contract_value',
        'remain_investment_value',
        'start_date',
        'end_date',
      ],
    }
  },
  computed: {
    ...mapState('investments', [
      'fundFormOptions',
    ]),
    ...mapState('portfolios', [
      'portfolios',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
      'dashboardViews',
    ]),
    sortedData () {
      if (this.sortDir === '') {
        return this.portfolios
      } else {
        this.sortPortfolios ( {key: this.sortKey, dir: this.sortDir} )
        return this.portfolios
      }
    },
    filteredData () {
      return this.sortedData.filter ( data => this.inAnyData(data, this.filterKeys, this.filterTexts) )
    },
  },
  methods: {
    ...mapActions('portfolios', [
      'getLpDetailPortfolios',
      'lpPortfolioDetailsOpen',
      'sortPortfolios',
    ]),
    ...mapActions('investments', [
      'getInvestmentData',
    ]),
    ...mapActions('dashboardViews', [
      'getDashboardViews',
      'selectDashboardView',
    ]),
    openDetail (portfolio) {
      this.setListEditView ()
      this.getInvestmentData(portfolio.investment_id).then ( () => {
        this.lpPortfolioDetailsOpen(portfolio)
        this.sideOpen = true
      })
    },
    doneEditing (resp) {
      this.sideOpen = false
      this.$store.dispatch('modalClose')
      console.log('done editing :', resp)
    },
    numberStyle (number) {
      return number ? Number(number).toLocaleString() : 0
    },
    inAnyData (data, filterKeys, filters) {
      if (filterKeys) {
        for (var ii = 0; ii < filterKeys.length; ii++) {
          var key = filterKeys[ii]
          if (!this.inAny (data[key], filters[key]))
            return false
        }
      }
      return true
    },
    inAny (source, filterTexts) {
      if (!filterTexts || filterTexts.length === 0)
        return true

      for (var ii = 0; ii < filterTexts.length; ii++) {
        if (source == filterTexts[ii])
          return true
      }
      return false
    },
    setListEditView () {
      if (this.currentDashboardView.view_layout === 'List Edit' && this.currentDashboardView.dashboard_view_name.substring(0,4) === 'Deal') {
        return
      }

      if (this.dashboardViews.length === 0) {
        this.getDashboardViews ()
      }

      for (var ii = 0; ii < this.dashboardViews.length; ii++) {
        if (this.dashboardViews[ii].view_layout === 'List Edit' && this.dashboardViews[ii].dashboard_view_name.substring(0,4) === 'Deal') {
          this.selectDashboardView(this.dashboardViews[ii])
          break
        }
      }
      return
    },
    selectLpPortfolios () {
      this.getLpDetailPortfolios({ created_on: this.createdOn, fund_form: this.fundForm, filter_text: this.investmentName })
    },
    toNumber (number) {
      if (number) {
        return numbro(this.uncomma(number)).format({ thousandSeparated: true })
      } else {
        return 0
      }
    },
    uncomma(str) {
      str = String(str)
      return str.replace(/[^(\d.)]+/g, '')
    },
  },
  mounted () {
    this.selectLpPortfolios ()
  },
}
</script>

<style lang='scss' scoped>
  th {
    @apply text-center py-3 px-2 border-b border-t uppercase tracking-wide;
    background-color: #FFF;
    white-space: nowrap;
    z-index: 2;
  }
  td {
    @apply py-3 pl-2 pr-2 border-b;
    white-space: nowrap;
  }

  .lp-portfolio-table-container {
    height: calc(100vh - 12rem);
  }


  @media (min-width: 1024px) {
    .lp-portfolio-table-container {
      height: calc(100vh - 14rem);
    }
  }
</style>
